// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import 'bootstrap'

Turbolinks.start()
global.Rails = Rails;

import "easy-autocomplete"
import './intl-phone';
import "packs/search"
import "packs/iframe"
import "packs/iframe-resizer"
import "./mailjet"
import "packs/copy"
import "packs/tooltip"
import "packs/multiselect"
import "packs/pagination"
import "packs/cookies"
import "packs/social"

import "stylesheets/application.scss"

Rails.start()
ActiveStorage.start()

