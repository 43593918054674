import Clipboard from 'clipboard'
import * as bootstrap from 'bootstrap'

document.addEventListener("turbolinks:load", function() {
  let clipboard = new Clipboard('.btn');

  clipboard.on('success', function(e) {
    console.info('Action:', e.action);
    console.info('Text:', e.text);
    console.info('Trigger:', e.trigger);
    let tooltip = bootstrap.Tooltip.getOrCreateInstance(e.trigger);
    tooltip.show()
    setTimeout(function(){
      tooltip.hide()
    }, 1500)
    e.clearSelection();
  });
});