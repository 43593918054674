
import { ModuleFactory as dashboardCodeFactory } from '@dashboardcode/bsmultiselect'
import { createPopper } from '@popperjs/core';

document.addEventListener("turbolinks:load", function() {
  let environment = { window, createPopper };
  let dashboardCode = dashboardCodeFactory(environment);
  environment.trigger = (element,eventName) => $(element).trigger(eventName);

  var tooltipTriggerList = [].slice.call(document.querySelectorAll("select[multiple='multiple']"))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return dashboardCode.BsMultiSelect(tooltipTriggerEl,  {})
  })
});