document.addEventListener("turbolinks:load", function() {
  if ($('input#search').length == 0) {
    return;
  }

  var options = {
    url: function(phrase) {
      return "/collectivites/search";
    },

    getValue: function(element) {
      return element.search_title
    },

    ajaxSettings: {
      dataType: "json",
      method: "POST",
      data: {
        dataType: "json"
      }
    },

    template: {
      type: "links",
      fields: {
        link: "url"
      }
    },

    list: {
      maxNumberOfElements: 15,
      onChooseEvent: function() {
        let selected = $('input#search').getSelectedItemData();
        location.replace(selected["url"]);
      }
    },

    preparePostData: function(data) {
      data.phrase = $('input#search').val();
      return data;
    }
  };


  $('input#search').easyAutocomplete(options);
});