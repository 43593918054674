document.addEventListener("turbolinks:load", function() {
  if (window.is_iframe) {
    $('a').click(function(event) {
      if ($(event.target).attr('data-post-event') == "postEvent") {
        let message = {
          from_ipl: true,
          ipl_follow_link: $(event.target).attr('href')
        };
        window.parent.postMessage(message, '*')
      }
      event.preventDefault();
    });
  }
});