
"turbolinks:load ajax:complete".split(" ").forEach(function(e){
  document.addEventListener(e, function() {
    $("a[data-pagination='paginate']").click(function(event) {
      event.preventDefault();
      let page = $(event.target).attr('data-page');
      $(event.target).parents('form').find('input[name="page"]').val(page);
      Rails.fire($(event.target).parents('form')[0], "submit")
    });
  });
});