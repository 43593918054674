import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';

$( document ).on('turbolinks:load', function() {
  if ($('.intl-phone').length > 0) {
    _.each($('.intl-phone'), (element) => {
      intlTelInput(element, {
        hiddenInput: "international_phone",
        preferredCountries: ['fr'],
        onlyCountries: ['fr'],
        utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.min.js"
      });
      $(element).parents('.form-group').find('.invalid-feedback').show();
    })
  }
})